import React, { createContext, useContext, useState, useMemo, ReactNode } from 'react';
import { TimeRange } from '@grafana/data';
import { attributionDateOptions, MAX_QUERYABLE_MONTHS, now } from '../constants/date';
import { createMonthRange } from '../utils/utils.date';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../constants/routes';

interface DatePickerContextProps {
  timeRangeIndex: number;
  onMonthChange: (newMonth: number) => void;
  monthOptions: TimeRange[];
  timeRange: TimeRange;
  isCurrentMonth: boolean;
  isDisabled: boolean;
}

const DatePickerContext = createContext<DatePickerContextProps | undefined>(undefined);

export const DatePickerProvider = ({ children }: { children: ReactNode }) => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const defaultMonth = searchParams.get('month') ? Number(searchParams.get('month')) : LAST_MONTH_INDEX;
  const [timeRangeIndex, setTimeRangeIndex] = useState(defaultMonth);

  // feature flag for additional months added to the date picker
  // feel free to delete if it's no longer useful
  const ffMoreDateOptions = MAX_QUERYABLE_MONTHS;
  const monthOptions = useMemo(() => {
    if (ffMoreDateOptions > MAX_QUERYABLE_MONTHS) {
      const extendedDateOptions = [...attributionDateOptions];
      for (let i = MAX_QUERYABLE_MONTHS + 1; i <= ffMoreDateOptions; i++) {
        extendedDateOptions.push(createMonthRange(i));
      }
      return extendedDateOptions;
    }
    return attributionDateOptions;
  }, [ffMoreDateOptions]);

  const timeRange = monthOptions[timeRangeIndex];
  const isCurrentMonth = timeRange.to.isSame(now);

  const onMonthChange = (newMonth: number) => {
    setTimeRangeIndex(newMonth);
    searchParams.set('month', newMonth.toString());
    history.replace({ search: searchParams.toString() });
  };

  const value = {
    timeRangeIndex,
    onMonthChange,
    monthOptions,
    timeRange,
    isCurrentMonth,
    isDisabled: !pathname.includes(ROUTES.Overview),
  };

  return <DatePickerContext.Provider value={value}>{children}</DatePickerContext.Provider>;
};
const LAST_MONTH_INDEX = 1;

export const useMonthPicker = () => {
  const context = useContext(DatePickerContext);
  if (context === undefined) {
    throw new Error('useDatePicker must be used within a DatePickerProvider');
  }
  return context;
};
