import React from 'react';

import { type SelectableValue, type TimeRange } from '@grafana/data';
import { Select } from '@grafana/ui';
import { format, getYear } from 'date-fns';
import { now } from '../../constants/date';

import { useMonthPicker } from '../../context/MonthPickerContext';

export function MonthDropdown() {
  const { monthOptions, timeRangeIndex, onMonthChange, isDisabled } = useMonthPicker();

  const onMonthUpdate = (val: SelectableValue<number>) => {
    onMonthChange(val.value!);
  };

  return (
    <Select
      disabled={isDisabled}
      options={getDateOptions(monthOptions)}
      value={timeRangeIndex}
      onChange={onMonthUpdate}
    />
  );
}

function getDateOptions(ranges: TimeRange[]) {
  return ranges.map((month, i) => {
    let label = `${format(month.to.toDate(), 'MMMM')} ${getYear(month.to.toDate())}`;
    if (month.to.isSame(now)) {
      label += ' To Date';
    }
    return {
      label,
      value: i,
    };
  });
}
