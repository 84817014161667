import { dateTime, FieldType, LoadingState, type DataFrame, type PanelData, type TimeRange } from '@grafana/data';
import {
  useLogsAttributionData,
  useLogsAttributionDataTotal,
  useLogsBillingDataTotal,
  useLogsStartTimestamp,
  useLogsTotalBillPanelData,
  useMetricTotalBillPanelData,
  useTracesAttributionData,
  useTracesAttributionDataTotal,
  useTracesBillingDataTotal,
  useTracesStartTimestamp,
  useTracesTotalBillPanelData,
} from '../../../hooks/overview';
import { checkPartialData } from '../../../utils/utils.date';
import { useEffect, useMemo, useState } from 'react';
import { useGetLabels } from '../../../hooks/configuration';
import { filterSelectedMonth } from '../../../utils/utils.formating';
import type { Attribution, ProductErrors } from '../../../types';
import { createCSV } from '../../../utils/utils.csv';
import { useGetRecords } from '../../../hooks/attributions';
import {
  checkEmptyDataSeries as isEmptyDataSeries,
  doneStateCheck,
  loadingStateCheck,
} from '../../../utils/utils.panels';
import { LOADING_TABLE_DATA } from '../../../constants/constant';
import { buildTableAndCSVDataFrames, type IsOverflow } from '../../../utils/utils.overviewDataFrames';

export function useIsFullMonthReport(timeRange: TimeRange) {
  // Get Logs Start Timestamp
  const logsStartTimestamp = useLogsStartTimestamp();
  const logsStartValues = logsStartTimestamp?.series[0]?.fields.filter((field) => field.name === 'Value')[0];
  const logsStartValue = logsStartValues ? logsStartValues.values[0] : null;

  // Get Traces Start Timestamp
  const tracesStartTimestamp = useTracesStartTimestamp();
  const tracesStartValues = tracesStartTimestamp?.series[0]?.fields.filter((field) => field.name === 'Value')[0];
  const tracesStartValue = tracesStartValues ? tracesStartValues.values[0] : null;

  let fullDataStartMonth = '';
  let fullCoverageLaggingMonth = '';
  const logPartialDataCheck = checkPartialData(logsStartValue, timeRange);
  const tracesPartialDataCheck = checkPartialData(tracesStartValue, timeRange);
  const fullBillingMonth = tracesPartialDataCheck.fullBillingMonth.isBefore(logPartialDataCheck.fullBillingMonth)
    ? dateTime(logPartialDataCheck.fullBillingMonth)
    : dateTime(tracesPartialDataCheck.fullBillingMonth);

  const isLogsPartial = logPartialDataCheck.partialData;
  const isTracesPartial = tracesPartialDataCheck.partialData;

  if (isLogsPartial || isTracesPartial) {
    fullDataStartMonth = fullBillingMonth.format('MMMM D');
    fullCoverageLaggingMonth = dateTime(fullBillingMonth).subtract(1, 'month').format('MMMM yyyy');
  }
  const isPartialData = useMemo(
    () => ({
      isLogsPartial,
      isTracesPartial,
      noLogsData: logPartialDataCheck.noDataThisMonth,
      noTracesData: tracesPartialDataCheck.noDataThisMonth,
      noTimestamps: logPartialDataCheck.noTimestamp && tracesPartialDataCheck.noTimestamp,
    }),
    [
      isLogsPartial,
      isTracesPartial,
      logPartialDataCheck.noDataThisMonth,
      tracesPartialDataCheck.noDataThisMonth,
      tracesPartialDataCheck.noTimestamp,
      logPartialDataCheck.noTimestamp,
    ]
  );

  return {
    isPartialData,
    fullDataStartMonth,
    fullCoverageLaggingMonth,
    timestampsDoneLoading: doneStateCheck([logsStartTimestamp, tracesStartTimestamp]),
  };
}

type OrgConfig = {
  metricsTenantId: string;
  logsTenantId: string;
  tracesTenantId: string;
  orgId: string;
  attributionLabels: string[];
  error?: Error | null;
  loading: boolean;
};
export function useGetOrgConfiguration() {
  // Use App Platform to get instance ids & configured label
  const { data: costAttributionLabelData, error, isLoading } = useGetLabels();

  const [orgSettings, setOrgSettings] = useState<OrgConfig>({
    metricsTenantId: '',
    logsTenantId: '',
    tracesTenantId: '',
    orgId: '',
    attributionLabels: [],
    error,
    loading: isLoading,
  });

  useEffect(() => {
    const item = costAttributionLabelData?.items[0];
    const labelNames = item?.spec?.labelNames;
    if (!isLoading) {
      setOrgSettings({
        metricsTenantId: item?.metadata?.labels?.['hm-instance-id'] || '',
        logsTenantId: item?.metadata?.labels?.['hl-instance-id'] || '',
        tracesTenantId: item?.metadata?.labels?.['ht-instance-id'] || '',
        orgId: item?.metadata?.labels?.['org-id'] || '',
        attributionLabels: labelNames ? labelNames : [],
        error,
        loading: isLoading,
      });
    }
  }, [costAttributionLabelData, error, isLoading]);

  return orgSettings;
}

export type PartialData = {
  isLogsPartial: boolean;
  isTracesPartial: boolean;
  noLogsData: boolean;
  noTracesData: boolean;
  noTimestamps: boolean;
};

export function useGetData(
  orgConfig: OrgConfig,
  timeRange: TimeRange,
  isCurrentMonth: boolean,
  isPartialData: PartialData,
  timestampsDoneLoading: boolean
) {
  const [dataFrames, setDataFrames] = useState<DataFrame[][]>(LOADING_TABLE_DATA);
  const [metricAttributions, setMetricAttributions] = useState<Attribution[] | null>(null);
  const [CSVdata, setCSVdata] = useState<string | null>(null);
  const [isOverflowData, setIsOverflowData] = useState<IsOverflow>({
    isMetricsOverflow: false,
    isLogsOverflow: false,
    isTracesOverflow: false,
  });
  // Get Total Costs for each product (top stat panel totals)
  const { metricsTenantId, logsTenantId, tracesTenantId, attributionLabels } = orgConfig;
  const metricTotalBillPanelData = useMetricTotalBillPanelData(metricsTenantId, timeRange);
  const logsTotalBillPanelData = useLogsTotalBillPanelData(logsTenantId, timeRange);
  const tracesTotalBillPanelData = useTracesTotalBillPanelData(tracesTenantId, timeRange);
  const totalBillPanelData = useMemo(() => {
    return buildTotalPanelData(
      metricTotalBillPanelData,
      logsTotalBillPanelData,
      tracesTotalBillPanelData,
      timeRange
    );
  }, [metricTotalBillPanelData, logsTotalBillPanelData, tracesTotalBillPanelData, timeRange]);

  const logsAttributionData = useLogsAttributionData(attributionLabels, timeRange);
  const tracesAttributionData = useTracesAttributionData(attributionLabels, timeRange);

  // Get total usage and cost so that we can update the values to be proportinal
  const logsBillingDataTotal = useLogsBillingDataTotal(logsTenantId, timeRange);
  const logsAttributionDataTotal = useLogsAttributionDataTotal(timeRange);
  const tracesBillingDataTotal = useTracesBillingDataTotal(tracesTenantId, timeRange);
  const tracesAttributionDataTotal = useTracesAttributionDataTotal(timeRange);

  const { data: allMetricAttributions, isLoading: metricsLoading, isError: metricsError } = useGetRecords();

  const noLogs = isEmptyDataSeries(logsAttributionData);
  const noTraces = isEmptyDataSeries(tracesAttributionData);
  const noAttributions = !metricAttributions?.length && noLogs && noTraces;
  const logsOrTracesLoading = loadingStateCheck([logsAttributionData, tracesAttributionData], timeRange);

  const errors: ProductErrors = useMemo(() => {
    return {
      logs:
        logsAttributionData?.errors ||
        logsAttributionDataTotal?.errors ||
        logsBillingDataTotal?.errors ||
        logsTotalBillPanelData?.errors,
      traces:
        tracesAttributionData?.errors ||
        tracesAttributionDataTotal?.errors ||
        tracesBillingDataTotal?.errors ||
        tracesTotalBillPanelData?.errors,
      metrics: metricTotalBillPanelData?.errors || metricsError,
      orgConfig: orgConfig.error,
    };
  }, [
    logsAttributionData,
    logsAttributionDataTotal,
    logsBillingDataTotal,
    logsTotalBillPanelData,
    tracesAttributionData,
    tracesAttributionDataTotal,
    tracesBillingDataTotal,
    tracesTotalBillPanelData,
    metricTotalBillPanelData,
    metricsError,
    orgConfig.error,
  ]);

  // Metric Attributions format data to last month and convert to DataFrame
  useEffect(() => {
    if (allMetricAttributions && allMetricAttributions.length > 0) {
      // Redact data to the last month
      const dataForMonth = filterSelectedMonth(allMetricAttributions, timeRange.to);
      setMetricAttributions(dataForMonth);
    }
  }, [allMetricAttributions, timeRange, attributionLabels, isCurrentMonth]);

  // Format transform metrics, logs, traces and create csv
  useEffect(() => {
    const formatData = async () => {
      const { tableData, csvData, isInOverflow } = await buildTableAndCSVDataFrames({
        metricAttributions: metricAttributions,
        metricTotalBillPanelData: metricTotalBillPanelData!,
        logsTotalBillPanelData: logsTotalBillPanelData!,
        logsAttributionData: logsAttributionData!,
        logsAttributionDataTotal: logsAttributionDataTotal!,
        logsBillingDataTotal: logsBillingDataTotal!,
        tracesTotalBillPanelData: tracesTotalBillPanelData!,
        tracesAttributionData: tracesAttributionData!,
        tracesAttributionDataTotal: tracesAttributionDataTotal!,
        tracesBillingDataTotal: tracesBillingDataTotal!,
        isPartialData,
        isCurrentMonth,
        attributionLabels,
        errors,
      });

      setIsOverflowData(isInOverflow);
      setDataFrames(tableData);
      const combinedCSV = createCSV(csvData);
      setCSVdata(combinedCSV);
    };

    const doneLoading = doneStateCheck([
      metricTotalBillPanelData,
      logsTotalBillPanelData,
      logsAttributionData,
      logsAttributionDataTotal,
      logsBillingDataTotal,
      tracesTotalBillPanelData,
      tracesAttributionData,
      tracesAttributionDataTotal,
      tracesBillingDataTotal,
    ]);

    if (!noAttributions && doneLoading && timestampsDoneLoading) {
      formatData();
    } else if (noAttributions && doneLoading) {
      setDataFrames(LOADING_TABLE_DATA);
    }
  }, [
    metricAttributions,
    metricTotalBillPanelData,
    logsTotalBillPanelData,
    logsAttributionData,
    logsAttributionDataTotal,
    logsBillingDataTotal,
    tracesTotalBillPanelData,
    tracesAttributionData,
    tracesAttributionDataTotal,
    tracesBillingDataTotal,
    isPartialData,
    isCurrentMonth,
    noAttributions,
    timestampsDoneLoading,
    attributionLabels,
    errors,
  ]);

  return {
    dataFrames,
    CSVdata,
    attributionsLoading: orgConfig.loading || logsOrTracesLoading || metricsLoading,
    errors,
    noStats:
      metricAttributions && metricAttributions.length === 0 && !logsTotalBillPanelData && !tracesTotalBillPanelData,
    noAttributions,
    noAttributionsEver: !allMetricAttributions?.length && isPartialData.noTimestamps,
    noMetrics: !metricAttributions?.length,
    noTraces,
    totalBillPanelData,
    metricTotalBillPanelData,
    logsTotalBillPanelData,
    tracesTotalBillPanelData,
    isOverflowData,
  };
}

function getTotalValue(panel?: PanelData) {
  return panel?.series[0]?.fields[1]?.values.at(-1) || 0;
}

function buildTotalPanelData(
  metricTotalBillPanelData: PanelData | undefined,
  logsTotalBillPanelData: PanelData | undefined,
  tracesTotalBillPanelData: PanelData | undefined,
  timeRange: TimeRange
): PanelData {
  const totalsLoading = loadingStateCheck(
    [metricTotalBillPanelData, logsTotalBillPanelData, tracesTotalBillPanelData],
    timeRange
  );
  let totalValue = 0;
  if (!totalsLoading) {
    totalValue =
      getTotalValue(metricTotalBillPanelData) +
      getTotalValue(logsTotalBillPanelData) +
      getTotalValue(tracesTotalBillPanelData);
  }

  return {
    timeRange,
    state: totalsLoading ? LoadingState.Loading : LoadingState.Done,
    series: [
      {
        refId: 'A',
        meta: {},
        fields: [
          {
            name: 'Value',
            type: FieldType.number,
            config: {
              displayNameFromDS: 'Total Cost',
            },
            values: [totalValue],
          },
        ],
        length: 1,
      },
    ],
  };
}
