import { RuntimeDataSource, sceneUtils } from '@grafana/scenes';
import { Attribution, DimLabel, FixedLabel, Label } from 'types';
import { DataQueryResponse, Field, FieldType, LoadingState, TestDataSourceResponse } from '@grafana/data';
import { getDimensions } from 'pages/Attributions/dimensions';
import { BILLABLE_SERIES, MONTH_ISO } from 'constants/constant';
import { Observable } from 'rxjs';

export const registerCustomDatasource = (records: Attribution[]) => {
  const typeMapping = new Map<string, FieldType>();
  typeMapping.set(BILLABLE_SERIES, FieldType.number);

  // Only keep dimension labels (starting with `label_`) and [monthISO, BillableSeries]
  const attributionDimensions: DimLabel[] = getDimensions(records);
  const valueFields: FixedLabel[] = [MONTH_ISO, BILLABLE_SERIES];
  const columns: Label[] = [...attributionDimensions, ...valueFields];

  const fields: Field[] = [];
  columns.forEach((column) => {
    const field: Field = {
      name: column,
      type: typeMapping.get(column) ?? FieldType.string,
      // TO Investigate: Can we speed this up?
      // Actually, based on the chrome dev-tools perf profile, this has no impact
      values: records.map((record) => record[column]),
      config: {},
    };
    fields.push(field);
  });

  class MyCustomDS extends RuntimeDataSource {
    query(): Promise<DataQueryResponse> | Observable<DataQueryResponse> {
      return Promise.resolve({
        state: LoadingState.Done,

        data: [
          {
            fields: fields,
            length: records.length,
          },
        ],
      });
    }

    testDatasource(): Promise<TestDataSourceResponse> {
      return Promise.resolve({ status: 'success', message: 'OK' });
    }
  }

  try {
    // Important to specify a unique pluginId and uid for your data source that is unlikely to confict with any other scene app plugin.
    sceneUtils.registerRuntimeDataSource({
      dataSource: new MyCustomDS('grafanacloud-attribution-report', 'grafanacloud-attribution-report'),
    });
  } catch (e) {
    console.warn('Scenes datasource already registered');
  }
};


